<template>
  <div class="Menu">

      {{categories}}
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pa-0">
          <v-row
            v-for="(category, c) in categories"
            v-bind:key="c"
            justify="center"
            class="background-black-menu"
          >
            <v-col cols="12" class="padding-subcategories-titles">
              <span class="subtitles-app-and-buttons">
                {{ category.name }}
              </span>
            </v-col>

            <v-col cols="12">
              <v-row class="position-relative">
                <!-- <template v-for="(subcategory, sr) in category.subcategory_linked">
                 <v-img src="@/assets/icons/accordion-icon.svg" class="accordion-icon" v-bind:key="sr"></v-img>
                </template> -->

                <v-col
                  v-for="(subcategory, s) in category.subcategory_linked"
                  v-bind:key="s"
                  v-bind:cols="12 / subcategory.columns"
                  v-bind:class="{ inverse_colors_borders: c > 1 }"
                  class="padding-subcategories borders"
                >
                  <span class="d-block titles-menu">
                    {{ subcategory.name }}
                  </span>
                  <span
                    class="d-block titles-kanji"
                    v-bind:class="{ inverse: c > 1 }"
                  >
                    {{ subcategory.name_kanji }}
                  </span>

                  <v-row justify="space-between">
                    <v-col
                      v-for="(dish, d) in subcategory.foodDishes_linked"
                      v-bind:key="d"
                      v-bind:cols="12 / dish.columns"
                    >
                      <span class="d-block subtitles-menu">
                        {{ dish.name }}
                      </span>
                      <span>
                        {{ dish.dish_1_text }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      categories: [],
    };
  },
  mounted() {
      var aux = this;
    //fetch para el menú
    // fetch(
    //   "https://www.srtanakacms.actstudio.xyz/api/collections/get/categories?token=1635b46f0568a19ed800dc70231e84",
    //   {
    //     method: "post",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({
    //       populate: 3,
    //     }),
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((res) => (this.categories = res.entries));

    window.addEventListener('message', function(event){
        aux.categories = event.data.entry;
    }, false);

    //evento que emite para cambiar los colores
    this.$emit("update", 1);
  },
  methods: {
    fill: function (event) {
        console.log(event.data);
        this.categories = event.data.entry;
    },
  },
};
</script>